import {
  DEMAND_TYPES_ABBR,
  getHouseDemandTypes,
  getGuarenteedDemandTypes,
} from "constants/DemandType";
import _ from "lodash";
import moment from "moment-timezone";

// to pretend if user is in this timezone
// moment.tz.setDefault("America/New_York");
// 1, 3, 6, 12 ,24
// 4, 8
const hourGroup = {
  0: { 1: 0, 3: 0, 4: 0, 6: 0, 8: 0, 12: 0, 24: 0 },
  1: { 1: 1, 3: 0, 4: 0, 6: 0, 8: 0, 12: 0, 24: 0 },
  2: { 1: 2, 3: 0, 4: 0, 6: 0, 8: 0, 12: 0, 24: 0 },

  3: { 1: 3, 3: 3, 4: 0, 6: 0, 8: 0, 12: 0, 24: 0 },
  4: { 1: 4, 3: 3, 4: 4, 6: 0, 8: 0, 12: 0, 24: 0 },
  5: { 1: 5, 3: 3, 4: 4, 6: 0, 8: 0, 12: 0, 24: 0 },

  6: { 1: 6, 3: 6, 4: 4, 6: 6, 8: 0, 12: 0, 24: 0 },
  7: { 1: 7, 3: 6, 4: 4, 6: 6, 8: 0, 12: 0, 24: 0 },
  8: { 1: 8, 3: 6, 4: 8, 6: 6, 8: 8, 12: 0, 24: 0 },

  9: { 1: 9, 3: 9, 4: 8, 6: 6, 8: 8, 12: 0, 24: 0 },
  10: { 1: 10, 3: 9, 4: 8, 6: 6, 8: 8, 12: 0, 24: 0 },
  11: { 1: 11, 3: 9, 4: 8, 6: 6, 8: 8, 12: 0, 24: 0 },

  12: { 1: 12, 3: 12, 4: 12, 6: 12, 8: 8, 12: 12, 24: 0 },
  13: { 1: 13, 3: 12, 4: 12, 6: 12, 8: 8, 12: 12, 24: 0 },
  14: { 1: 14, 3: 12, 4: 12, 6: 12, 8: 8, 12: 12, 24: 0 },

  15: { 1: 15, 3: 15, 4: 12, 6: 12, 8: 8, 12: 12, 24: 0 },
  16: { 1: 16, 3: 15, 4: 16, 6: 12, 8: 16, 12: 12, 24: 0 },
  17: { 1: 17, 3: 15, 4: 16, 6: 12, 8: 16, 12: 12, 24: 0 },

  18: { 1: 18, 3: 18, 4: 16, 6: 18, 8: 16, 12: 12, 24: 0 },
  19: { 1: 19, 3: 18, 4: 16, 6: 18, 8: 16, 12: 12, 24: 0 },
  20: { 1: 20, 3: 18, 4: 20, 6: 18, 8: 16, 12: 12, 24: 0 },

  21: { 1: 21, 3: 21, 4: 20, 6: 18, 8: 16, 12: 12, 24: 0 },
  22: { 1: 22, 3: 21, 4: 20, 6: 18, 8: 16, 12: 12, 24: 0 },
  23: { 1: 23, 3: 21, 4: 20, 6: 18, 8: 16, 12: 12, 24: 0 },
};

// so that o line in chart appears on top
export const GROUP_TYPE_INDEX = {
  d1: 1,
  d2: 2,
  d3: 3,
  d4: 4,
  d5: 5,
  d6: 6,
  d7: 7,
  d8: 8,
  d9: 9,
  d10: 10,
  b: 11,
  o: 12,
};

const PERFORMANCE_METRICS = [
  "layer1_req",
  "layer2_req",
  "layer1_catchall_req",
  "layer1_unfilled_imp",
  "layer2_unfilled_imp",
  //
  // Ad Exchange
  "layer1_adx_imp",
  "layer1_adx_rev",
  "layer2_adx_imp",
  "layer2_adx_rev",
  // AdSense
  "layer1_ads_imp",
  "layer1_ads_rev",
  "layer2_ads_imp",
  "layer2_ads_rev",
  // Exchange Bidding
  "layer1_eb_imp",
  "layer1_eb_rev",
  "layer2_eb_imp",
  "layer2_eb_rev",
  // Network
  "layer1_nw_imp",
  "layer1_nw_rev",
  "layer2_nw_imp",
  "layer2_nw_rev",
  // Price Priority
  "layer1_pp_imp",
  "layer1_pp_rev",
  "layer2_pp_imp",
  "layer2_pp_rev",
  // Price Priority Ghost Impressions
  "layer1_pp_ghost_imp",
  "layer2_pp_ghost_imp",
  // Bulk
  "layer1_bk_imp",
  "layer1_bk_rev",
  "layer2_bk_imp",
  "layer2_bk_rev",
  // Header Bidding
  "layer1_hb_imp",
  "layer1_hb_rev",
  "layer2_hb_imp",
  "layer2_hb_rev",
  // Billable House
  "layer1_bh_imp",
  "layer1_bh_rev",
  "layer2_bh_imp",
  "layer2_bh_rev",
  // Mediation
  "layer1_md_imp",
  "layer1_md_rev",
  "layer2_md_imp",
  "layer2_md_rev",
  // SDK Bidding
  "layer1_sdkb_imp",
  "layer1_sdkb_rev",
  "layer2_sdkb_imp",
  "layer2_sdkb_rev",
  // Sponsorship
  "layer1_ss_imp",
  "layer1_ss_rev",
  "layer2_ss_imp",
  "layer2_ss_rev",
  // Standard
  "layer1_std_imp",
  "layer1_std_rev",
  "layer2_std_imp",
  "layer2_std_rev",
  // Preferred Deal
  "layer1_pd_imp",
  "layer1_pd_rev",
  "layer2_pd_imp",
  "layer2_pd_rev",
  // House
  "layer1_house_imp",
  "layer1_house_rev",
  "layer2_house_imp",
  "layer2_house_rev",
];

// o's godview
export function calculateReportsForGodview({
  data,
  segmentId,
  aggregation,
  demandTypes,
  costCpm,
  includeToday,
  filterLatestDate,
  isIncludeGhostImp,
}) {
  let perfData = _.filter(data.performance_report, {
    upr_segment_id: segmentId,
  });

  if (!includeToday) {
    perfData = _.filter(perfData, (r) => r.time <= filterLatestDate);
  }

  // get the highest rpm detector
  // ** need to calculate metrics first!
  const preCalcReports = calculateByTimeAggregation(perfData, aggregation);
  const cReports = calculateReports({
    reports: preCalcReports,
    demandTypes,
    costCpm,
    isIncludeGhostImp,
  });

  let godviewReports = _(cReports)
    .groupBy("time")
    .reduce((result, d, time) => {
      const maxData = _.maxBy(d, (dd) => {
        return _.startsWith(dd.group_type, "d") && dd._layerall_rrpm;
      });

      const newData = {
        ...maxData,
        group_type: "godview",
        __group_type_of_god: maxData.group_type,
      };

      result.push([...d, newData]);
      return result;
    }, []);

  const reports = _(godviewReports).reduce((result, data) => {
    // const oData = _.find(data, { group_type: "o" });
    const osData = _.find(data, { group_type: "godview" });
    // const bData = _.find(data, { group_type: "b" });
    // if (oData) result.push(oData);
    if (osData) result.push(osData);
    // if (bData) result.push(bData);
    return result;
  }, []);
  // console.log(reports);

  return reports;
}

// o vs detector
export function calculateReportsForOptVsDetectorCompare({
  data,
  segmentId,
  aggregation,
  demandTypes,
  costCpm,
  includeToday,
  filterLatestDate,
  isIncludeGhostImp,
}) {
  let priceData = _.filter(data.floor_price_report, {
    upr_segment_id: segmentId,
  });
  let perfData = _.filter(data.performance_report, {
    upr_segment_id: segmentId,
  });

  if (!includeToday) {
    priceData = _.filter(priceData, (r) => r.time <= filterLatestDate);
    perfData = _.filter(perfData, (r) => r.time <= filterLatestDate);
  }

  let oVsDetectorPrices = _(priceData)
    .groupBy("time")
    .reduce((result, d, time) => {
      const oData = _.find(d, { group_type: "o" });
      const dData = _.find(d, (dd) => {
        return dd.group_type !== "b" && dd.price === oData.price;
      });

      if (!dData) {
        return result;
      }

      result.push({
        time,
        d: dData.group_type,
        price: oData.price,
      });

      return result;
    }, []);
  oVsDetectorPrices = _.keyBy(oVsDetectorPrices, "time");

  // add a new group type into performance reports: o_shadow
  let perfDataByTime = _(perfData)
    .groupBy("time")
    .mapValues((reports, time) => {
      const op = oVsDetectorPrices[time];
      const newReport = _.find(reports, { group_type: op.d });
      let oShadowReport = {
        ...newReport,
        group_type: "o_shadow",
        __group_type_of_o: aggregation === 1 ? op.d : null,
      };

      return [...reports, oShadowReport];
    })
    .value();

  const reports = _(perfDataByTime).reduce((result, data) => {
    const oData = _.find(data, { group_type: "o" });
    const osData = _.find(data, { group_type: "o_shadow" });
    const bData = _.find(data, { group_type: "b" });
    if (oData) result.push(oData);
    if (osData) result.push(osData);
    if (bData) result.push(bData);
    return result;
  }, []);

  const timeAggReports = calculateByTimeAggregation(reports, aggregation);
  const shouldCalculateDT = true;
  const filteredData = calculateReports({
    reports: timeAggReports,
    demandTypes,
    costCpm,
    shouldCalculateDT,
    isIncludeGhostImp,
  });
  // console.log(filteredData);
  return filteredData;
}

export function calculateReportsForDemandTypeCompare({
  reports,
  aggregation,
  demandTypes,
  costCpm,
  isIncludeGhostImp,
}) {
  const timeAggReports = calculateByTimeAggregation(reports, aggregation);
  const filteredData = calculateReports({
    reports: timeAggReports,
    demandTypes,
    costCpm,
    isIncludeGhostImp,
  });

  return filteredData;
}

export function getLatestDateFromReports(reports) {
  const dates = _.sortBy(_.keys(_.keyBy(reports, "date")));
  const latestDate = _.last(dates);
  return latestDate;
}

// calculate reports for anatomy dashboard when filter changed
export function calculateDashboardReports({
  reports,
  aggregation,
  demandTypes,
  costCpm,
  filterLatestDate, // the latest date to remove from reports (if null, nothing to remove)
  isIncludeGhostImp,
}) {
  // console.log(
  //   aggregation,
  //   demandTypes,
  //   costCpm,
  //   filterLatestDate,
  //   isIncludeGhostImp
  // );
  // for performance charts
  let { performance_report, floor_price_report } = reports;
  if (filterLatestDate) {
    performance_report = _.filter(
      performance_report,
      (r) => r.time <= filterLatestDate
    );
    floor_price_report = _.filter(
      floor_price_report,
      (r) => r.time <= filterLatestDate
    );
  }
  // console.log("performance_report", performance_report);

  const timeAggReports = calculateByTimeAggregation(
    performance_report,
    aggregation
  );

  const filteredData = calculateReports({
    reports: timeAggReports,
    demandTypes,
    costCpm,
    isIncludeGhostImp,
  });

  const segment_performance_report = calculateSegmentPerformanceReports({
    reports: timeAggReports,
    aggregation,
    demandTypes,
    costCpm,
  });

  const groupTypes = _(filteredData)
    .groupBy("group_type")
    .keys()
    .sortBy((gt) => {
      if (gt === "o") return 1;
      if (gt === "b") return 2;
      if (gt === "x") return 3;
      if (_.startsWith(gt, "d")) {
        return _.parseInt(gt.substring(1)) + 3;
      }
    })
    .value();

  // console.log("-----", filteredData);

  return {
    ...reports,
    floor_price_report,
    performance_report: filteredData,
    segment_performance_report,
    groupTypes,
  };
}

export function calculateSegmentPerformanceReports({
  reports,
  demandTypes,
  costCpm,
}) {
  const segment_performance_reports = _(reports)
    .groupBy((r) => `${r.upr_segment_id}_${r._time}`) // _time (aggregated time)
    .mapValues((reportsOfSameTimeSlot) => {
      const perf_reports = _(reportsOfSameTimeSlot)
        .groupBy((r) => (r.group_type === "b" ? "b" : "m"))
        .mapValues((groupReports) => {
          const layer1_req = _.sumBy(groupReports, "layer1_req");
          const layer2_req = _.sumBy(groupReports, "layer2_req");
          const cost = layer2_req > 0 ? costCpm * (layer2_req / 1000) : 0;

          const layer1_rev = _.sumBy(groupReports, (r) =>
            _getLayerMetric(r, demandTypes, 1, "rev")
          );
          const layer2_rev = _.sumBy(groupReports, (r) =>
            _getLayerMetric(r, demandTypes, 2, "rev")
          );
          const rev = layer1_rev + layer2_rev;

          const rpm = layer1_req > 0 ? rev / (layer1_req / 1000) : 0;

          return {
            layer1_req,
            rpm,
            rev,
            cost,
          };
        })
        .value();

      const layer1_b_req = _.get(perf_reports, ["b", "layer1_req"], 0);
      const layer1_m_req = _.get(perf_reports, ["m", "layer1_req"], 0);
      const total_req = layer1_b_req + layer1_m_req;

      const layer1_b_rev = _.get(perf_reports, ["b", "rev"], 0);
      const layer1_m_rev = _.get(perf_reports, ["m", "rev"], 0);
      const total_rev = layer1_b_rev + layer1_m_rev;

      const layer1_b_cost = _.get(perf_reports, ["b", "cost"], 0);
      const layer1_m_cost = _.get(perf_reports, ["m", "cost"], 0);
      const total_cost = layer1_b_cost + layer1_m_cost;

      const b_rpm = _.get(perf_reports, ["b", "rpm"], 0);
      const original_rev = total_req > 0 ? b_rpm * (total_req / 1000) : 0; // Simulated
      const increased_rev = total_rev - original_rev;

      const gross_lift = original_rev > 0 ? increased_rev / original_rev : 0;
      const net_lift =
        original_rev > 0 ? (increased_rev - total_cost) / original_rev : 0;

      const rpm = total_req > 0 ? total_rev / (total_req / 1000) : 0;

      const rr = _.minBy(reportsOfSameTimeSlot, "time");
      return {
        upr_segment_id: rr.upr_segment_id,
        time: rr.time,
        _time: rr._time,
        date_hour: `${rr.date} ${rr.start_hour}`,
        total_req,
        total_rev,
        original_rev,
        increased_rev,
        cost: total_cost,
        gross_lift,
        net_lift,
        rpm,
        b_rpm,
      };
    })
    .values()
    .groupBy("upr_segment_id")
    .value();

  return segment_performance_reports;
}

function _getAggregatedTime(report, aggregation) {
  const r = report;
  // date + start_hour
  const hr = hourGroup[r.start_hour][aggregation];
  const time = r.time - (r.start_hour - hr) * 3600;
  return time;
}

export function calculateByTimeAggregation(reports, aggregation) {
  // console.log("calculateByTimeAggregation", reports);
  // 1H : 0,1,2,3,4,5,6,7,8,9,10...
  // 3H : 0,3,6,9,12,15,18,21
  // 6H : 0,6,12,18
  // 12H: 0,12
  // 24H: 0
  const performance_report = _(reports)
    .groupBy((r) => _getAggregatedTime(r, aggregation))
    .mapValues((reportsOfSameTimeSlot, aggrTime) => {
      // sum all the metrics
      // group by upr segment, group_type!
      return _(reportsOfSameTimeSlot)
        .groupBy((r) => `${r.upr_segment_id}_${r.group_type}`)
        .mapValues((detailReports) => {
          const report = detailReports[0];
          let r = {
            _time: parseInt(aggrTime),
            time: report.time,
            date: report.date,
            start_hour: report.start_hour,
            upr_segment_id: report.upr_segment_id,
            group_type: report.group_type,
          };

          if (report.__group_type_of_o) {
            r.__group_type_of_o = report.__group_type_of_o;
          }

          if (report.__group_type_of_god) {
            r.__group_type_of_god = report.__group_type_of_god;
          }

          for (let m of PERFORMANCE_METRICS) {
            r[m] = _.sumBy(detailReports, m);
          }

          return r;
        })
        .values()
        .value();
    })
    .values()
    .flatten()
    .value();

  return performance_report;
  // return {
  //   ...reports,
  //   performance_report,
  // };
}

export function calculateReports({
  reports,
  demandTypes,
  costCpm,
  shouldCalculateDT = false, // calculate metrics for every demand type
  isIncludeGhostImp = false,
}) {
  let performance_report = _.map(reports, (r) => {
    let layer1_imp = _getLayerMetric(r, demandTypes, 1, "imp");
    const layer1_rev = _getLayerMetric(r, demandTypes, 1, "rev");
    let layer2_imp = _getLayerMetric(r, demandTypes, 2, "imp");
    const layer2_rev = _getLayerMetric(r, demandTypes, 2, "rev");
    if (!isIncludeGhostImp && _.indexOf(demandTypes, "Price Priority") !== -1) {
      layer1_imp = layer1_imp - r["layer1_pp_ghost_imp"];
      layer2_imp = layer2_imp - r["layer2_pp_ghost_imp"];
    }
    const layerall_imp = layer1_imp + layer2_imp;
    const layerall_rev = layer1_rev + layer2_rev;

    const layer1_req = r.layer1_req;
    const layer2_req = r.layer2_req;
    const cost = layer2_req > 0 ? costCpm * (layer2_req / 1000) : 0;

    const layer1_catchall_req = r.layer1_catchall_req;
    const layer_loss =
      layer1_catchall_req > 0
        ? _.round(100 * (1 - layer2_req / layer1_catchall_req), 4)
        : 0;

    const layer1_unfilled_imp = r.layer1_unfilled_imp;
    const layer2_unfilled_imp = r.layer2_unfilled_imp;
    const layerall_unfilled_imp = layer1_unfilled_imp + layer2_unfilled_imp;

    const layer1_rrpm =
      layer1_req > 0 ? _.round((layer1_rev * 1000) / layer1_req, 3) : 0;
    const layer2_rrpm =
      layer2_req > 0 ? _.round((layer2_rev * 1000) / layer2_req, 3) : 0;

    // Gross rRPM
    const layerall_rrpm =
      layer1_req > 0 ? _.round((layerall_rev * 1000) / layer1_req, 3) : 0;
    // Only layer all needs to see performance after extra adserving cost
    const layerall_rrpm_net =
      layer1_req > 0
        ? _.round(((layerall_rev - cost) * 1000) / layer1_req, 3)
        : 0;

    const layer1_str =
      layer1_req > 0 ? _.round((layer1_imp / layer1_req) * 100, 2) : 0;
    const layer2_str =
      layer2_req > 0 ? _.round((layer2_imp / layer2_req) * 100, 2) : 0;
    const layerall_str =
      layer1_req > 0 ? _.round((layerall_imp / layer1_req) * 100, 2) : 0;

    const layer1_str_guarenteed = _calculateGuarenteedSTR(r, 1);
    const layer2_str_guarenteed = _calculateGuarenteedSTR(r, 2);
    const layerall_str_guarenteed = _calculateGuarenteedSTR(r, "all");
    const layer1_str_house = _calculateHouseSTR(r, 1);
    const layer2_str_house = _calculateHouseSTR(r, 2);
    const layerall_str_house = _calculateHouseSTR(r, "all");
    const layer1_str_catchall =
      layer1_req > 0 ? _.round((layer1_catchall_req / layer1_req) * 100, 2) : 0;

    const layer1_ecpm =
      layer1_imp > 0 ? _.round((layer1_rev * 1000) / layer1_imp, 3) : 0;
    const layer2_ecpm =
      layer2_imp > 0 ? _.round((layer2_rev * 1000) / layer2_imp, 3) : 0;
    const layerall_ecpm =
      layerall_imp > 0 ? _.round((layerall_rev * 1000) / layerall_imp, 3) : 0;

    // 3 layers: l1+l2, l1, l2
    // rrpm: rev /req / 1000 -> (rev * 1000) / req
    // str: imp / req
    // ecpm: (rev * 1000) / imp

    let dtMetrics = {};
    if (shouldCalculateDT) {
      dtMetrics = _calculateMetricsPerDemandType({ demandTypes, report: r });
    }

    let rr = {
      ...r,
      ...dtMetrics,
      _layer1_imp: layer1_imp,
      _layer_loss: layer_loss,

      // _layer1_rev: layer1_rev,
      // _layer2_rev: layer2_rev,
      // _layerall_rev: layerall_rev,

      _layer1_rrpm: layer1_rrpm,
      _layer2_rrpm: layer2_rrpm,
      _layerall_rrpm: layerall_rrpm,
      _layerall_rrpm_net: layerall_rrpm_net,
      _cost: cost,
      _layerall_rev: layerall_rev,
      // _layerall_incr_rev: layerall_incr_rev,

      _layer1_req: layer1_req,
      _layer1_catchall_req: layer1_catchall_req,
      _layer1_unfilled_imp: layer1_unfilled_imp,
      _layer2_unfilled_imp: layer2_unfilled_imp,
      _layerall_unfilled_imp: layerall_unfilled_imp,

      _layer1_str: layer1_str,
      _layer2_str: layer2_str,
      _layerall_str: layerall_str,
      _layer1_str_catchall: layer1_str_catchall,
      _layer1_str_guarenteed: layer1_str_guarenteed,
      _layer2_str_guarenteed: layer2_str_guarenteed,
      _layerall_str_guarenteed: layerall_str_guarenteed,
      _layer1_str_house: layer1_str_house,
      _layer2_str_house: layer2_str_house,
      _layerall_str_house: layerall_str_house,

      _layer1_ecpm: layer1_ecpm,
      _layer2_ecpm: layer2_ecpm,
      _layerall_ecpm: layerall_ecpm,
      _layer2_req: layer2_req,
      _layer_loss: layer_loss,
    };
    return rr;
  });

  const bReportsGrouped = _(performance_report)
    .filter((r) => {
      return r.group_type === "b";
    })
    .keyBy((r) => {
      return `${r.upr_segment_id}_${r._time}`;
    })
    .value();

  performance_report = _.map(performance_report, (r) => {
    const b = _.get(bReportsGrouped, `${r.upr_segment_id}_${r._time}`);

    // calculate layerall increased rev
    const b_rpm = _.get(b, "_layerall_rrpm", 0);
    const original_rev = r._layer1_req > 0 ? b_rpm * (r._layer1_req / 1000) : 0;
    const increased_rev = r._layerall_rev - original_rev;

    return {
      ...r,
      _layerall_incr_rev: increased_rev,
    };
  });

  return performance_report;
}

function _calculateGuarenteedSTR(report, layerNum) {
  const guarteedTypes = _.map(getGuarenteedDemandTypes(), (t) => {
    return DEMAND_TYPES_ABBR[t];
  });
  const imp = _.sumBy(guarteedTypes, (t) => {
    if (layerNum === "all") {
      return report[`layer1_${t}_imp`] + report[`layer2_${t}_imp`];
    }
    return report[`layer${layerNum}_${t}_imp`];
  });
  const req =
    layerNum === "all" ? report[`layer1_req`] : report[`layer${layerNum}_req`];
  const str = req > 0 ? _.round((imp / req) * 100, 2) : 0;
  return str;
}

function _calculateHouseSTR(report, layerNum) {
  const includePpGhostImp = true;
  const guarteedTypes = _.map(getHouseDemandTypes(), (t) => {
    return DEMAND_TYPES_ABBR[t];
  });
  const imp = _.sumBy(guarteedTypes, (t) => {
    let r = 0;
    if (layerNum === "all") {
      r = report[`layer1_${t}_imp`] + report[`layer2_${t}_imp`];
      if (includePpGhostImp) {
        r += report[`layer1_pp_ghost_imp`] + report[`layer2_pp_ghost_imp`];
      }
    } else {
      r = report[`layer${layerNum}_${t}_imp`];
      if (includePpGhostImp) {
        r += report[`layer${layerNum}_pp_ghost_imp`];
      }
    }
    return r;
  });
  const req =
    layerNum === "all" ? report[`layer1_req`] : report[`layer${layerNum}_req`];
  const str = req > 0 ? _.round((imp / req) * 100, 2) : 0;
  return str;
}

function _calculateMetricsPerDemandType({ demandTypes, report: r }) {
  let dtMetrics = {};
  for (let dt of demandTypes) {
    const dtt = DEMAND_TYPES_ABBR[dt];

    const layer1_imp = r[`layer1_${dtt}_imp`];
    const layer2_imp = r[`layer2_${dtt}_imp`];
    const layer1_rev = r[`layer1_${dtt}_rev`];
    const layer2_rev = r[`layer2_${dtt}_rev`];
    const layerall_imp = layer1_imp + layer2_imp;
    const layerall_rev = layer1_rev + layer2_rev;

    ////// gross rrpm
    dtMetrics[`_layer1_${dtt}_rrpm`] =
      r.layer1_req > 0 ? _.round((layer1_rev * 1000) / r.layer1_req, 3) : 0;
    dtMetrics[`_layer2_${dtt}_rrpm`] =
      r.layer2_req > 0 ? _.round((layer2_rev * 1000) / r.layer2_req, 3) : 0;
    dtMetrics[`_layerall_${dtt}_rrpm`] =
      r.layer1_req > 0 ? _.round((layerall_rev * 1000) / r.layer1_req, 3) : 0;

    /////// str
    dtMetrics[`_layer1_${dtt}_str`] =
      r.layer1_req > 0 ? _.round((layer1_imp / r.layer1_req) * 100, 2) : 0;
    dtMetrics[`_layer2_${dtt}_str`] =
      r.layer2_req > 0 ? _.round((layer2_imp / r.layer2_req) * 100, 2) : 0;
    dtMetrics[`_layerall_${dtt}_str`] =
      r.layer1_req > 0 ? _.round((layerall_imp / r.layer1_req) * 100, 2) : 0;

    /////// ecpm
    dtMetrics[`_layer1_${dtt}_ecpm`] =
      layer1_imp > 0 ? _.round((layer1_rev * 1000) / layer1_imp, 3) : 0;
    dtMetrics[`_layer2_${dtt}_ecpm`] =
      layer2_imp > 0 ? _.round((layer2_rev * 1000) / layer2_imp, 3) : 0;

    dtMetrics[`_layerall_${dtt}_ecpm`] =
      layerall_imp > 0 ? _.round((layerall_rev * 1000) / layerall_imp, 3) : 0;
  }

  return dtMetrics;
}

export function transformReports(rawReports) {
  // to json
  // parseInt
  // prepcalulations
  const reports = transformReportsToJson(rawReports);
  const performance_report = _(reports.performance_report)
    .map((r) => {
      let rr = r;
      for (let m of _.keys(rr)) {
        if (
          _.endsWith(m, "rev") ||
          _.endsWith(m, "req") ||
          _.endsWith(m, "imp")
        ) {
          rr[m] = parseInt(rr[m]);
        }

        if (_.endsWith(m, "rev")) {
          rr[m] = _normalizeRevenue(rr[m]);
        }
      }

      return rr;
    })
    .value();

  return {
    ...reports,
    // floor_price_report,
    performance_report,
  };
}

function transformReportsToJson(reports) {
  const floor_price_report = _transformToJson(reports.floor_price_report);
  const performance_report = _transformToJson(reports.performance_report);

  return {
    ...reports,
    floor_price_report,
    performance_report,
  };
}

export function getSegmentReports(reports, segmentId) {
  const floor_price_report = _.filter(reports.floor_price_report, {
    upr_segment_id: segmentId,
  });
  const performance_report = _.filter(reports.performance_report, {
    upr_segment_id: segmentId,
  });

  return {
    floor_price_report,
    performance_report,
  };
}

// layerNum: 1, metric: "imp"
function _getLayerMetric(report, demandTypes, layerNum, metric) {
  let metricData = 0;
  for (let dt of demandTypes) {
    // Ad Exchange -> adx
    metricData += _.get(
      report,
      [`layer${layerNum}_${DEMAND_TYPES_ABBR[dt]}_${metric}`],
      0
    );
  }
  return metricData;
}

export function getPerformanceSectionData({ reports }) {
  const data = _(reports.performance_report).groupBy("group_type").value();

  return {
    data,
  };
}

export function getDetectorFloorPriceMap({ reports }) {
  const priceSectionData = _(reports.floor_price_report)
    .filter((d) => {
      // -1 means no floor price was set
      return (
        d.group_type !== "b" && d.group_type !== "o" && d.group_type !== "x"
      );
    })
    .groupBy("group_type")
    .value();

  // get latest floor price of every detector
  const detectorFloorPriceMap = _.mapValues(priceSectionData, (dReports) => {
    return _.get(_.last(dReports), "price", "-");
  });

  return detectorFloorPriceMap;
}

export function getSegmentPriceSectionData({ reports }) {
  const priceSectionData = _(reports.floor_price_report)
    .filter((d) => {
      // -1 means no floor price was set
      return d.group_type !== "b" && d.price !== -1;
    })
    .groupBy("group_type")
    .value();
  const layer1Data = _(reports.performance_report)
    .filter(
      (d) =>
        d.group_type !== "b" && d.group_type !== "o" && d.group_type !== "x"
    )
    .groupBy("group_type")
    .value();

  return {
    priceSectionData,
    layer1Data,
  };
}

export function _formatMoneyWithCurrency({ currency, value }) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

function _isDateBetween({ date, startDate, endDate }) {
  return moment(date).isBetween(startDate, endDate, undefined, "[]");
}

function _normalizeRevenue(revenue) {
  return revenue / 1000000;
}

function _transformExchangeRates(exchangeRates) {
  /*
  {
    USD: {
      "2020-09": 1
    },
    ...
  }
  */
  let currencyMap = {};
  _.forEach(exchangeRates, (e) => {
    currencyMap[e.currency] = _.reduce(
      e.rates,
      (result, r) => {
        const month = r[0];
        const rate = r[1];
        result[month] = rate;
        return result;
      },
      {}
    );
  });

  return currencyMap;
}

function _transformToJson(reports) {
  // transform reports to json format
  const { cols, rows } = reports;

  return _.reduce(
    rows,
    (result, row) => {
      let r = {};
      _.forEach(cols, (column, index) => {
        r[column] = row[index];
      });
      result.push(r);
      return result;
    },
    []
  );
}
